<template>
  <div class="pn-change" :class="classNames.root">
    <div dir="rtl">
      <span
        v-if="value || (!value && displayZero)"
        v-text="format(value)"
        :class="cn('whitespace-pre', classNames?.span)"
        dir="ltr"
      />
      <span v-else :class="cn(['text-primary', classNames?.span])">-</span>

      <span v-if="!hideSymbol" v-text="value && value > 0 ? '+' : ''" :class="classNames?.span" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ClassValue } from "clsx"

export type PositiveNegativeClasses = {
  root: ClassValue
  span: ClassValue
  success: ClassValue
  danger: ClassValue
  idle: ClassValue
}

export interface PositiveNegativeProps {
  value: Nullable<number>
  format: (v: Nullable<number>) => string
  hideSymbol?: boolean
  classes?: Partial<PositiveNegativeClasses>
  displayZero?: boolean
}

const props = withDefaults(defineProps<PositiveNegativeProps>(), { value: 0 })

const classNames = computed(() => ({
  root: cn([
    "font-medium",
    "pn-change",
    props.classes?.root,
    props.value! < 0 && cn("text-destructive", props.classes?.danger),
    props.value! > 0 && cn("text-success", props.classes?.success),
    props.value === 0 && cn("text-black/50", props.classes?.idle),
  ]),
  span: cn([props.classes?.span]),
}))
</script>
